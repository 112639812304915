<template>
    <InvestProject />
</template>

<script>
import InvestProject from '@apps/InvestProject/index.vue'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        InvestProject
    }
}
</script>